export interface CreateRightOfUseRequest {
  onlineServiceId: string;
  operatorId: string;
  regionalKey: string;
  producerId: string;
}

export enum PricingModel {
  FREE = "FREE",
  CHARGEABLE = "CHARGEABLE",
  NOT_SPECIFIED = "NOT_SPECIFIED"
}

export enum AfterUseModel {
  MODEL_A = "MODEL_A" // EFA-Leistung
}

export interface RightOfUse {
  id: string;
  onlineServiceId: string;
  onlineServiceIdentifier: string;
  operatorId: string;
  operatorName: string;
  platformId?: string;
  platformName?: string;
  createdAt: string;
  rightOfUseArea: string;
  areaOfUseId: string;
  regionalKey: string;
  pricingModel?: PricingModel;
  afterUseModel?: AfterUseModel;
}

export interface PlatformAssignment {
  pricingModel: PricingModel;
  afterUseModel?: AfterUseModel | null;
  platformId?: string;
}

export interface AreaOfUseIdentifier {
  rightOfUseId: string;
  areaOfUseId: string;
}

export interface RightOfPlatformAssignment {
  id: string;
  pricingModel: PricingModel;
  afterUseModel: AfterUseModel | null;
  platformId: string;
  producerId: string;
  operatorId: string;
}

export interface RightOfPlatformAssignmentView extends RightOfPlatformAssignment {
  platformName: string;
  producerName: string;
}

export type AssignPlatformToRightOfUse = PlatformAssignment & AreaOfUseIdentifier;

export interface PrivateRightOfUse {
   id: string
   onlineServiceId: string
   onlineServiceIdentifier: string
   key: string
   platformId: string
   platformName: string
   producerId: string
   producerName: string
   operatorId: string
   operatorName: string
   createdAt: string
   updatedAt: string
}