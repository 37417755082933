import { MARKETPLACE_URL_BASE, mktClient } from "../../../common/libs/axiosClient";
import {
  AssignPlatformToRightOfUse,
  RightOfPlatformAssignment,
  CreateRightOfUseRequest,
  PlatformAssignment,
  RightOfUse, PrivateRightOfUse
} from "./RightOfUse";
import { ONLINE_SERVICES_URL } from "../onlinedienste/onlineDiensteApi";

interface RightOfUseRequestParams {
  operatorId?: string;
  producerId?: string;
}
export const USAGE_RIGHTS_URL = `${MARKETPLACE_URL_BASE}/right-of-use`;
const PRIVATE_RIGHTS_OF_USE_URL = `${MARKETPLACE_URL_BASE}/right-of-use/private`;
export const PLATFORM_ASSIGNMENT_URL = ({ rightOfUseId, areaOfUseId }: { rightOfUseId: string; areaOfUseId: string }) =>
  `${MARKETPLACE_URL_BASE}/right-of-use/${rightOfUseId}/area-of-use/${areaOfUseId}/platform-assignment`;
const RIGHTS_OF_PLATFORM_ASSIGNMENT_URL = `${USAGE_RIGHTS_URL.toString()}/producer-assignment-rights`;
export const deleteOnlineServiceById = (id: string) => mktClient.delete(`${ONLINE_SERVICES_URL.toString()}/${id}`);
export const fetchRightsOfUse = (params?: RightOfUseRequestParams) =>
  mktClient.get<RightOfUse[]>(USAGE_RIGHTS_URL.toString(), { params }).then<RightOfUse[]>((response) => response.data);

export const fetchRightOfUseByIdAndOperator = (id: string) =>
  mktClient
    .get<RightOfUse>(`${MARKETPLACE_URL_BASE}/right-of-use/operator/${id}`)
    .then<RightOfUse>((response) => response.data);
export const fetchRightOfUseByIdAndProducer = (id: string, selectedOrganization: string) =>
  mktClient
    .get<RightOfUse>(`${MARKETPLACE_URL_BASE}/right-of-use/producer/${selectedOrganization}/${id}`)
    .then<RightOfUse>((response) => response.data);

export const fetchPlatformAssignment = ({
  operatorId,
  rightOfUseId,
  areaOfUseId
}: {
  operatorId: string | null;
  rightOfUseId: string;
  areaOfUseId: string;
}) =>
  mktClient
    .get<PlatformAssignment>(PLATFORM_ASSIGNMENT_URL({ rightOfUseId, areaOfUseId }), { params: { operatorId } })
    .then<PlatformAssignment>((response) => response.data);

export const updatePlatformAssignment = ({
  operatorId,
  rightOfUseId,
  areaOfUseId,
  platformAssignment
}: {
  operatorId: string | null;
  rightOfUseId: string;
  areaOfUseId: string;
  platformAssignment: PlatformAssignment;
}) =>
  mktClient
    .put<PlatformAssignment>(PLATFORM_ASSIGNMENT_URL({ rightOfUseId, areaOfUseId }), platformAssignment, {
      params: { operatorId }
    })
    .then<PlatformAssignment>((response) => response.data);

export const addRightOfUse = (body: CreateRightOfUseRequest) => mktClient.post<void>(USAGE_RIGHTS_URL.toString(), body);
export const deleteRightOfUseById = ({ id, areaOfUseId }: { id: string; areaOfUseId: string }) =>
  mktClient.delete(`${USAGE_RIGHTS_URL.toString()}/${id}/${areaOfUseId}`);

export const updateRightOfUse = (body: AssignPlatformToRightOfUse[]) =>
  mktClient.patch<void>(`${USAGE_RIGHTS_URL.toString()}/platform`, body);

export const createOrUpdateProducerRightOfPlatformAssignment = (body: RightOfPlatformAssignment) => {
  if (body.id.replaceAll(" ", "") !== "") {
    return mktClient
      .put<RightOfPlatformAssignment>(`${RIGHTS_OF_PLATFORM_ASSIGNMENT_URL}`, body)
      .then((response) => response.data);
  }
  return mktClient
    .post<RightOfPlatformAssignment>(`${RIGHTS_OF_PLATFORM_ASSIGNMENT_URL}`, body)
    .then((response) => response.data);
};

export const deleteProducerRightOfPlatformAssignment = (assignment: RightOfPlatformAssignment) =>
  mktClient.delete(`${RIGHTS_OF_PLATFORM_ASSIGNMENT_URL}/id/${assignment.id}`);

export const getProducerRightsOfPlatformAssignment = (operatorId: string | null) =>
  mktClient
    .get<RightOfPlatformAssignment[]>(`${RIGHTS_OF_PLATFORM_ASSIGNMENT_URL}`, {
      params: { operator: operatorId ?? "" }
    })
    .then((response) => response.data);

export const getPrivateRightsOfUse = ({ producerId }: { producerId?: string }) => 
  mktClient
    .get<PrivateRightOfUse[]>(`${PRIVATE_RIGHTS_OF_USE_URL}`, {
      params: { producer: producerId ?? "" }
    })
    .then((response) => response.data);

export const deletePrivateRightOfUseById = ({ id }: { id: string }) =>
  mktClient.delete(`${PRIVATE_RIGHTS_OF_USE_URL.toString()}/${id}`);